import React from 'react';
import * as styles from "./ShowRooms.module.scss";

const ShowRooms = () => {
    return (
        <div>
        <div className={styles.whiteBack}>
        <h1>We eagerly welcome conversations with clients and members of the MASTER WiZR team!</h1>
            </div>
        </div>
    );
};

export default ShowRooms;
