import React from 'react';
import * as styles from './OurTeam.module.scss';
import Members from "../../molecules/Members";
import background from "../../../images/team-background.jpg";
import StyledButton from '../../atoms/StyledButton';

const OurTeam = () => {
    return (
        <div className={styles.container} style={{backgroundImage: `url(${background})`}}>
            <div className={styles.innerContainer}>
                <h2 className={styles.title}>The Master Team</h2>
                <p className={styles.description}>Our master team of more than 60 professionals includes engineers, marketing and social media specialists, service providers, and researchers.</p>
                <Members />
                <h3 className={styles.topTitle} style={{maxWidth:'50rem', textAlign:'center'}}> Want to be a part of our team? </h3>
                <h2 className={styles.bottomTitle} style={{maxWidth:'50rem', textAlign:'center'}}> Join our <span>W</span>orld! </h2>
                <StyledButton extraClass="bluebtn2" title="Open Job Positions" icon link="/hiring" />
            </div>
        </div>
    );
};

export default OurTeam;
