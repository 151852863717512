import React from "react";
import * as styles from "./PdfSection.module.scss";

function PdfSection({title,desc,extraClass}) {
  return (
    <div className={`${styles.manuals} ${styles[extraClass]}`}>
      <div className={styles.top}>
        <h1>{title}</h1>
       {desc &&  <p>{desc} </p>}
      </div>
    </div>
  );
}
export default PdfSection;
