import founding1 from "../images/found-slide1.png";
import founding2 from "../images/found-slide2.png";
import founding3 from "../images/found-slide3.png";
import founding4 from "../images/found-slide4.png";
import founding5 from "../images/found-slide5.png";
import founding6 from "../images/found-slide6.png";
import founding7 from "../images/found-slide7.png";
import founding8 from "../images/found-slide8.png";
import founding9 from "../images/found-slide9.png";
import founding10 from "../images/found-slide10.png";
import founding11 from "../images/found-slide11.png";

import connect1 from "../images/magconnect1.png";
import connect2 from "../images/magconnect2.png";
import connect3 from "../images/magconnect3.png";
import connect4 from "../images/magconnect4.png";
import connect5 from "../images/magconnect5.png";
import connect6 from "../images/magconnect6.png";
import connect7 from "../images/magconnect7.png";
import connect8 from "../images/magconnect8.png";
import connect9 from "../images/magconnect9.png";
import connect10 from "../images/magconnect10.png";
import connect11 from "../images/magconnect11.png";

import visual1 from "../images/visualmap1.jpg";
import visual2 from "../images/visualmap2.jpg";
import visual3 from "../images/visualmap3.jpg";
import visual4 from "../images/visualmap4.jpg";
import visual5 from "../images/visualmap5.jpg";
import visual6 from "../images/visualmap6.jpg";
import visual7 from "../images/visualmap7.jpg";
import visual8 from "../images/visualmap8.jpg";

import getwizr1 from "../images/getwizr1.png";
import getwizr2 from "../images/getwizr22.png";
import getwizr3 from "../images/getwizr3.png";
import getwizr4 from "../images/getwizr4.png";
import getwizr5 from "../images/getwizr5.png";
import getwizr6 from "../images/getwizr6.png";
import getwizr7 from "../images/getwizr7.png";
import getwizr8 from "../images/getwizr8.png";
import getwizr9 from "../images/getwizr9.png";
import getwizr10 from "../images/getwizr10.png";




export const data=[
founding1,founding2,founding3,founding4,founding5,founding8,founding6,founding7,founding9,founding11,founding10
]
export const connectData = [
    connect1,connect2,connect3,connect4,connect5,connect6,connect7,connect8,connect9,connect10,connect11
]
export const visualData=[
    visual1,visual2,visual3,visual4,visual5,visual6,visual7,visual8
]
export const getwizrMagazine = [
    getwizr1,getwizr2,getwizr3,getwizr4,getwizr5,getwizr6,getwizr7,getwizr8, getwizr9,getwizr10
]