import * as styles from "./SocialMedia.module.scss";
import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import Instagram from "../../../images/insta.svg";
import Facebook from "../../../images/fb.svg";
import LinkedIn from "../../../images/linkedin.svg";
import Like from "../../../images/like.svg";

function SocialMedia({ extraClass }) {
  const [state, setVideo] = useState("");
  const [width, setWidth] = useState(0);
  
  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);
  
  const data = [
    {
      title: "Shining professionally is our motto, but we don’t always get it right",
      url: "https://res.cloudinary.com/dsg2ktuqk/video/upload/v1674052402/mwvideos/wconnectindex2-2_egbj6i.mp4",
      image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710790211/Templates/Rectangle_79_1_n7stel.png",
      toLink: "https://www.facebook.com/100064039384916/videos/496888135209870/?__so__=permalink",
      number: "207.8K"
    },
    {
      title: "Connect better, stand out and enjoy the process.",
      url: "https://res.cloudinary.com/dsg2ktuqk/video/upload/v1674052402/mwvideos/wconnectindex2-2_egbj6i.mp4",
      image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710868440/Templates/Rectangle_79_2_nmbhqw.png",
      toLink: "https://www.facebook.com/masterwizr/videos/572728950968235",
      number: "425.6K"
    },
    {
      title: "From virtual to real, silly times in the studio",
      url: "https://res.cloudinary.com/dsg2ktuqk/video/upload/v1674065005/mwvideos/intromodules_isudka.mp4",
      image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710790212/Templates/Rectangle_79_do8q5a.png",
      toLink: "https://www.facebook.com/masterwizr/videos/shining-professionally-is-also-about-having-fun-at-work/662338461815165",
      number: "316.8K"
    },
  ];

  const handleClick = (link) => {
    // Check if link is an external URL
    if (link.startsWith('http')) {
      // Open external link in a new tab
      window.open(link, '_blank');
    } else {
      // Use Gatsby's navigate function for internal navigation
      navigate(`/${link}`);
    }
  };

  return (
    <>
      <div className={`${styles.container} ${styles[extraClass]}`}>
        <div className={styles.content}>
          <h1 className={styles.title}>Check us out</h1>
          <div className={styles.descIcons}>
            <p className={styles.description}>Join us on Facebook with another +170000 followers or other platforms</p>
            <div className={styles.icons}>
              <Instagram onClick={() => window.open("https://www.instagram.com/masterwizr/", '_blank')} />
              <Facebook  onClick={() => window.open("https://www.facebook.com/masterwizr", '_blank')} />
              <LinkedIn  onClick={() => window.open("https://www.linkedin.com/company/masterwizr/", '_blank')} />
            </div>
          </div>
          <div className={styles.data}>
            {data.map((item, i) => (
              <div key={i} className={styles.card} onClick={() => handleClick(item.toLink)}>
                <div className={styles.thumbnail}>
                  <img src={item.image} alt="Video thumbnail" onClick={() => setVideo(item.url)} />
                </div>
                <div className={styles.bottom}>
                  <h2>{item.title}</h2>
                  <div className={styles.numbers}>
                    <Like />
                    <span className={styles.number}>{item.number} <span className={styles.views}>views</span></span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default SocialMedia;
