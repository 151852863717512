// extracted by mini-css-extract-plugin
export var bottom = "SocialMedia-module--bottom--14dbb";
export var card = "SocialMedia-module--card--65aa0";
export var container = "SocialMedia-module--container--1c704";
export var content = "SocialMedia-module--content--0868b";
export var data = "SocialMedia-module--data--00fd6";
export var descIcons = "SocialMedia-module--descIcons--b5756";
export var description = "SocialMedia-module--description--64c25";
export var icons = "SocialMedia-module--icons--e9ad1";
export var number = "SocialMedia-module--number--bdbc9";
export var numbers = "SocialMedia-module--numbers--9d14b";
export var thumbnail = "SocialMedia-module--thumbnail--72665";
export var title = "SocialMedia-module--title--5d184";
export var views = "SocialMedia-module--views--9d4e6";